<template>
  <c-box
    w="100%"
    max-width="590px"
  >
    <c-form-control margin-bottom="80px">
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Saran Gizi dan Gaya Hidup
      </c-form-label>
      <!-- <c-input-group size="md"> -->
      <textarea
        id="address"
        v-model="value_.advice"
        v-chakra
        rows="3"
        placeholder="Masukkan Saran Gizi dan Gaya Hidup"
        d="block"
        border="1px solid #E2E8F0"
        border-radius="md"
        minHeight="150px"
        type="text"
        lineHeight="20px"
        p="10px"
        w="100%"
      />
      <!-- </c-input-group> -->
    </c-form-control>

    <c-form-control
      margin-bottom="30px"
      display="flex"
    >
      <c-button
        background-color="superLightGray.900"
        px="26px"
        font-size="18px"
        font-weight="500"
        py="26px"
        border-radius="58px"
        width="100%"
        color="darkGray.900"
        margin-right="20px"
        as="router-link"
        :to="{name:'nutri.profile-gizi'}"
        margin-bottom="20px"
      >
        Batal
      </c-button>
      <c-button
        background-color="primary.400"
        px="26px"
        font-size="18px"
        font-weight="500"
        py="26px"
        border-radius="58px"
        width="100%"
        color="white"
        @click="$emit('submit')"
      >
        Simpan
      </c-button>
    </c-form-control>
  </c-box>
</template>

<script>
export default {
  name: 'EditNutritionAdviceForm',
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      value_: this.value,
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) { this.value_ = val },
    },
    value_(val) {
      this.$emit('input', val)
    },
  },
}
</script>
