<template>
  <c-flex
    flex="1"
    flex-dir="column"
    overflow="auto"
    pb="30px"
  >
    <c-box
      px="40px"
      py="40px"
    >
      <c-heading
        color="black.900"
        font-size="24px"
        font-weight="700"
        margin-bottom="40px"
        text-transform="uppercase"
      >
        Saran Gizi &amp; GAYA HIDUP
      </c-heading>
      <EditNutritionAdviceForm
        v-model="nutritionAdvice"
        @submit="submit"
      />
    </c-box>
  </c-flex>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import EditNutritionAdviceForm from './forms/edit-nutrition-advice-form.vue'

export default {
  name: 'NutritionAdviceEdit',
  components: { EditNutritionAdviceForm },
  data() {
    return {
      nutritionAdvice: {},
      programId: null,
    }
  },
  computed: {
    ...mapState({
      saran: (s) => s.mealPlan.saran,
    }),
    clientId() {
      return this.$route.params.clientId
    },
  },
  watch: {
    saran: {
      immediate: true,
      handler(val, old) {
        if (val !== old) {
          this.nutritionAdvice = val
        }
      },
    },
  },
  async mounted() {
    await this.getSaran(this.clientId)
    this.programId = await this.$store
      .dispatch('mealPlan/getLatestProgramForNutri', this.clientId)
      .then((it) => it.id)
  },
  methods: {
    ...mapActions({
      getSaran: 'mealPlan/getSaran',
      updateSaran: 'mealPlan/updateSaran',
      createSaran: 'mealPlan/createSaran',
    }),
    submit() {
      let promise
      if (this.nutritionAdvice.id) {
        promise = this.updateSaran({
          saran: this.nutritionAdvice,
          clientId: this.clientId,
          programId: this.programId,
        })
      } else {
        promise = this.createSaran({
          saran: this.nutritionAdvice,
          clientId: this.clientId,
          programId: this.programId,
        })
      }
      promise.then(() => {
        this.$router.push({
          name: 'nutri.profile-gizi',
          params: { clientId: this.clientId },
        })
      })
    },
  },
}
</script>
