var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "w": "100%",
      "max-width": "590px"
    }
  }, [_c('c-form-control', {
    attrs: {
      "margin-bottom": "80px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Saran Gizi dan Gaya Hidup ")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.value_.advice,
      expression: "value_.advice"
    }, {
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "id": "address",
      "rows": "3",
      "placeholder": "Masukkan Saran Gizi dan Gaya Hidup",
      "d": "block",
      "border": "1px solid #E2E8F0",
      "border-radius": "md",
      "minHeight": "150px",
      "type": "text",
      "lineHeight": "20px",
      "p": "10px",
      "w": "100%"
    },
    domProps: {
      "value": _vm.value_.advice
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.value_, "advice", $event.target.value);
      }
    }
  })], 1), _c('c-form-control', {
    attrs: {
      "margin-bottom": "30px",
      "display": "flex"
    }
  }, [_c('c-button', {
    attrs: {
      "background-color": "superLightGray.900",
      "px": "26px",
      "font-size": "18px",
      "font-weight": "500",
      "py": "26px",
      "border-radius": "58px",
      "width": "100%",
      "color": "darkGray.900",
      "margin-right": "20px",
      "as": "router-link",
      "to": {
        name: 'nutri.profile-gizi'
      },
      "margin-bottom": "20px"
    }
  }, [_vm._v(" Batal ")]), _c('c-button', {
    attrs: {
      "background-color": "primary.400",
      "px": "26px",
      "font-size": "18px",
      "font-weight": "500",
      "py": "26px",
      "border-radius": "58px",
      "width": "100%",
      "color": "white"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('submit');
      }
    }
  }, [_vm._v(" Simpan ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }